import { inject, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanActivateChildFn, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth:AuthService = inject(AuthService);

  return auth.isAuthenticated$.pipe(map(authenticated => {

    // Redirect unauthenticated users to the login page
    // TODO: Set the return URL so that they are redirected back after authentication
    if (!authenticated)
      return auth.getLoginUrlTree();

    // In Development mode log authorization details
    if (isDevMode()) {
      console.group("Authorization by Authenticated Guard");
      console.debug("Accessed route: " + route.url);
      if (authenticated)
        console.debug("Access Granted");
      else
        console.debug("Access Denied");
      console.groupEnd();
    }

    return authenticated;
  }));
}

export const AuthGuardChild: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot, 
  state: RouterStateSnapshot
) => AuthGuard(childRoute, state);
